export default (callback) => {
  onMounted(() => {
    window.addEventListener('resize', onResize);
    window.addEventListener('orientationchange', onResize);

    onResize();
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', onResize);
    window.removeEventListener('orientationchange', onResize);
  });

  function onResize() {
    callback(window.innerWidth, window.innerHeight, window.devicePixelRatio);
  }
};
