<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.39989 15.1912L0.808594 13.5999L6.40859 7.99989L0.808594 2.39989L2.39989 0.808594L7.99989 6.40859L13.5999 0.808594L15.1912 2.39989L9.59119 7.99989L15.1912 13.5999L13.5999 15.1912L7.99989 9.59119L2.39989 15.1912Z"
      fill="white"
    />
  </svg>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  svg {
    width: px(16);
  }
</style>
