<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5881 19.228L11.2462 12.8861C10.7502 13.2702 10.1782 13.5749 9.53017 13.8002C8.88217 14.0256 8.18951 14.1383 7.4522 14.1383C5.59966 14.1383 4.03028 13.4952 2.74405 12.2089C1.45781 10.9227 0.814697 9.35332 0.814697 7.50078C0.814697 5.64825 1.45781 4.07887 2.74405 2.79263C4.03028 1.5064 5.59966 0.863281 7.4522 0.863281C9.30473 0.863281 10.8741 1.5064 12.1603 2.79263C13.4466 4.07887 14.0897 5.64825 14.0897 7.50078C14.0897 8.24208 13.977 8.93475 13.7516 9.57878C13.5263 10.2228 13.2216 10.7908 12.8375 11.2829L19.1853 17.6367L17.5881 19.228ZM7.4522 11.8633C8.66633 11.8633 9.69695 11.4397 10.544 10.5926C11.3911 9.74553 11.8147 8.71492 11.8147 7.50078C11.8147 6.28665 11.3911 5.25603 10.544 4.40893C9.69695 3.56183 8.66633 3.13828 7.4522 3.13828C6.23806 3.13828 5.20745 3.56183 4.36035 4.40893C3.51325 5.25603 3.0897 6.28665 3.0897 7.50078C3.0897 8.71492 3.51325 9.74553 4.36035 10.5926C5.20745 11.4397 6.23806 11.8633 7.4522 11.8633Z"
      fill="white"
    />
  </svg>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  svg {
    width: px(20);
  }
</style>
